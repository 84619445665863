<template>
  <div>
    <Loading :enable="loading"/>
    <b-row class="card mt-5">
      <div class="w-100">
        <h5 class="title-card">
          <i class="fas fa-user"></i> Dados do cliente
        </h5>
      </div>
      <div class="w-100 p-3">
        <b-form>
          <b-row class="pb-5">
            <b-col lg="12">
              <b-form-group>
                <b-form-checkbox v-model="orcamento" size="lg" name="check-button" switch>
                  Modo orçamento
                </b-form-checkbox>
              </b-form-group>
            </b-col>
            <b-col lg="12" class="mt-3" v-if="!orcamento">
              <b-form-group label="Nome do cliente">
                  <Autocomplete
                  ref="cities"
                  :source="clients"
                  input-class="form-control"
                  resultsValue="id"
                  @selected="setClient"
                  placeholder="Pesquisar o cliente"
                />
              </b-form-group>
            </b-col>
            <!-- <b-col lg="4">
              <b-form-group label="Tipo de construção">
                <b-form-select
                v-model="payload.type_construction"
                  :options="types_construction"
                />
              </b-form-group>
            </b-col> -->

            <b-col lg="4">
              <b-form-group label="Estado">
                <b-form-select
                v-model="province_id"
                @change="getCities"
                :options="provinces"/>
                <b-form-invalid-feedback>
                  Preenchimento obrigatório
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>

            <b-col lg="4">
              <b-form-group label="Cidade">
                 <Autocomplete
                  :disableInput="!cities.length"
                  ref="cities"
                  :source="cities"
                  input-class="form-control"
                  resultsValue="id"
                  @selected="setCity"
                  placeholder="Selecione primeiro o estado"
                />
                <b-form-invalid-feedback>
                  Preenchimento obrigatório
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </div>
    </b-row>

     <b-row class="card mt-5">
      <div class="w-100">
        <h5 class="title-card">
          <i class="fas fa-user"></i> Informações do projeto
        </h5>
      </div>
      <div class="w-100 p-3">
        <h4>Selecione o tipo de telhado</h4>
        <b-row>
          <b-col class="roofs">
            <div class="w-100 py-3">
              <div
              id="colonial"
              @click="setRoof(2)"
              :class="{ 'selected': payload.roof_type_id === 2 }"
              :style="{ 'background-image': `url(${telhado_colonial}` }"
              class="roof-item">
                <div class="roof-hover">
                  <i class="fas fa-check-circle"></i>
                </div>
              </div>
              <div class="w-100 text-center mt-2">
                <span>Colonial</span>
              </div>
            </div>
          </b-col>
          <b-col class="roofs">
            <div class="w-100 py-3">
              <div
              @click="setRoof(3)"
              :class="{ 'selected': payload.roof_type_id === 3 }"
              :style="{ 'background-image': `url(${telhado_laje}` }"
              class="roof-item">
                <div class="roof-hover">
                  <i class="fas fa-check-circle"></i>
                </div>
              </div>
              <div class="w-100 text-center mt-2">
                <span>Laje</span>
              </div>
            </div>
          </b-col>
          <b-col class="roofs">
            <div class="w-100 py-3">
              <div
              @click="setRoof(7)"
              :class="{ 'selected': payload.roof_type_id === 7 }"
              :style="{ 'background-image': `url(${telhado_paraf_madeira}` }"
              class="roof-item">
                <div class="roof-hover">
                  <i class="fas fa-check-circle"></i>
                </div>
              </div>
              <div class="w-100 text-center mt-2">
                <span>Paraf Madeira</span>
              </div>
            </div>
          </b-col>
          <b-col class="roofs">
            <div class="w-100 py-3">
              <div
              @click="setRoof(8)"
              :class="{ 'selected': payload.roof_type_id === 8 }"
              :style="{ 'background-image': `url(${telhado_paraf_metal}` }"
              class="roof-item">
                <div class="roof-hover">
                  <i class="fas fa-check-circle"></i>
                </div>
              </div>
              <div class="w-100 text-center mt-2">
                <span>Paraf Metal</span>
              </div>
            </div>
          </b-col>
          <b-col class="roofs">
            <div class="w-100 py-3">
              <div
              @click="setRoof(4)"
              :class="{ 'selected': payload.roof_type_id === 4 }"
              :style="{ 'background-image': `url(${telhado_trapezoidal}` }"
              class="roof-item">
                <div class="roof-hover">
                  <i class="fas fa-check-circle"></i>
                </div>
              </div>
              <div class="w-100 text-center mt-2">
                <span>Trapezoidal</span>
              </div>
            </div>
          </b-col>
          <b-col class="roofs">
            <div class="w-100 py-3">
              <div
              @click="setRoof(9)"
              :class="{ 'selected': payload.roof_type_id === 9 }"
              :style="{ 'background-image': `url(${usina_solo}` }"
              class="roof-item">
                <div class="roof-hover">
                  <i class="fas fa-check-circle"></i>
                </div>
              </div>
              <div class="w-100 text-center mt-2">
                <span>Usina solo</span>
              </div>
            </div>
          </b-col>
        </b-row>
        <div class="w-100 py-3">
          <b-row>
            <b-col lg="4">
              <b-form-group label="Padrão de voltagem">
                <b-form-select
                :class="{ 'form-group--error': $v.payload.voltage_stand.$error }"
                v-model="$v.payload.voltage_stand.$model"
                :state="$v.payload.voltage_stand.$dirty ?
                !$v.payload.voltage_stand.$error : null"
                :options="options_voltage"/>
                <b-form-invalid-feedback>
                  Preenchimento obrigatório
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col lg="6">
              <b-form-group label="Kwh">
                <b-form-input
                :class="{ 'form-group--error': $v.payload.power.$error }"
                v-model="$v.payload.power.$model"
                :state="$v.payload.power.$dirty ?
                !$v.payload.power.$error : null"
                />
                <b-form-invalid-feedback>
                  O valor deve ser maior que 300Kwh
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col lg="2">
              <b-form-group label="Telhado m²">
                <b-form-input v-model="payload.metreage"></b-form-input>
              </b-form-group>
            </b-col>

            <b-col>
              <b-button variant="primary" @click="getKits">Buscar Kits</b-button>
            </b-col>
          </b-row>
        </div>
      </div>
     </b-row>

    <b-row class="card mt-5" v-if="show_kits">
      <div class="w-100">
        <h5 class="title-card">
          <i class="fas fa-solar-panel"></i> Selecione o kit desejado
        </h5>
      </div>

      <div class="w-100 p-3">
        <b-row class="mt-3">
          <b-col lg="3">
            <b-form-group label="Comissão sobre a venda">
              <b-input-group append="%">
                <b-form-input
                :class="{ 'form-group--error': $v.payload.agent_percentage.$error }"
                v-model="$v.payload.agent_percentage.$model"
                :state="$v.payload.agent_percentage.$dirty ?
                !$v.payload.agent_percentage.$error : null"
                :options="options_voltage"
                v-mask="'##'"></b-form-input>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col lg="4" class="align-btn">
            <b-button variant="warning" @click="applyDiscount">
              Aplicar desconto
            </b-button>
          </b-col>
        </b-row>
        <b-row class="mt-3">
          <b-col lg="6" class="mt-3" v-for="kit in kits" :key="kit.panel_name">
            <div class="w-100 border p-3 kit">
              <div v-show="kit.percentage_discount" class="percentage_discount">
                - {{ kit.percentage_discount }}%
              </div>
              <div class="d-flex justify-content-center logos-container">
                <div class="d-flex align-items-center justify-content-center w-50">
                  <img :src="image_byd" v-if="kit.panel_name === 'byd'" class="w-70" alt="">
                  <img :src="image_trina" v-if="kit.panel_name === 'trina'" class="w-70" alt="">
                  <img :src="image_sunnova" v-if="kit.panel_name.toUpperCase() === 'SUNNOVA'" class="w-70" alt="">
                  <img :src="image_sunnergy" v-if="kit.panel_name.toUpperCase() === 'SUNERGY'" class="w-70" alt="">
                  <img :src="image_tsun" v-if="kit.panel_name.toUpperCase() === 'TSUN'" class="w-70" alt="">
                  <img :src="image_dah" v-if="kit.panel_name.toUpperCase() === 'DAH'" class="w-60" alt="">
                  <img :src="image_osda" v-if="kit.panel_name.toUpperCase() === 'OSDA'" class="w-60" alt="">
                  <img :src="image_leapton" v-if="kit.panel_name.toUpperCase() === 'LEAPTON'" class="w-70" alt="Leapton">
                  <img
                  :src="image_jinko"
                  v-if="kit.panel_name === 'jinko-575' || kit.panel_name === 'jinko-450' || kit.panel_name === 'jinko-550'"
                  class="w-70" alt="Jinko">
                </div>
                <div class="d-flex align-items-center justify-content-center w-50">
                  <img :src="image_fronius" v-if="kit.inverter_name === 'fronius'" class="w-70" alt="">
                  <img :src="image_growatt" v-if="kit.inverter_name === 'growatt'" class="w-70" alt="">
                  <img :src="image_goodwe" v-if="kit.inverter_name === 'goodwe'" class="w-70" alt="">
                  <img :src="image_refusol" v-if="kit.inverter_name === 'refusol'" class="w-70" alt="">
                  <img :src="image_deye" v-if="kit.inverter_name === 'deye'" class="w-70" alt="">
                  <img :src="image_saj" v-if="kit.inverter_name === 'saj'" class="w-70" alt="">
                  <img :src="image_sungrow" v-if="kit.inverter_name === 'sungrow'" class="w-70" alt="">
                </div>
              </div>
              <div class="d-flex card-info-kit">
                <div class="total-panels">
                  <h3>
                    {{ kit.generators_total_panels }}
                  </h3>
                  <span>Painéis</span>
                </div>
                <div class="power">
                  <h3>{{ kit.total_estimate_power}}</h3>
                  <span>kwh</span>
                </div>
              </div>
              <div class="d-flex justify-content-center">
                <div class="text-right">
                  <h3 class="d-inline-block" :class="{ 'text-line-through':  kit.discount_value > 0}">
                    {{ kit.price | currency}}
                  </h3>
                  <h6 class="text-danger mb-0" v-show="kit.discount_value > 0">
                  - {{ kit.discount_value | currency}}
                  </h6>
                </div>
              </div>
              <h2 class="text-center" v-show="kit.price_with_discount">
              <hr>
                {{ kit.price_with_discount | currency}}
              </h2>
              <div class="text-center w-100 border p-3 my-3" v-show="kit.price_with_discount">
                <h5>{{ kit.comission | currency}}</h5>
                <h6 class="text-success">Comissão sobre a venda</h6>
              </div>
              <div class="text-center" v-if="!orcamento">
                <b-button variant="info" @click="setKit(kit)">Selecionar</b-button>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
    </b-row>
  </div>
</template>
<script>
import Autocomplete from 'vuejs-auto-complete';
import { required, maxValue, minValue } from 'vuelidate/lib/validators';
import ClientService from '@/Services/ClientService';
import ProjectService from '@/Services/ProjectService';
import HelperService from '@/Services/HelperService';
import KitService from '@/Services/KitService';
import TelhadoColonial from '@/assets/images/telhado-colonial.jpg';
import TelhadoParafMadeira from '@/assets/images/telhado-paraf-madeira.jpg';
import TelhadoParafMetal from '@/assets/images/telhado-paraf-metal.jpg';
import TelhadoTrapezoidal from '@/assets/images/telhado-trapezoidal.jpg';
import UsinaSolo from '@/assets/images/usina-solo.jpg';
import TelhadoLaje from '@/assets/images/telhado-laje.png';
import BYD from '@/assets/images/byd.png';
import TRINA from '@/assets/images/trina.svg';
import JINKO from '@/assets/images/jinko.png';
import AESOLAR from '@/assets/images/aesolar.png';
import DAH from '@/assets/images/dah.png';
import SUNNOVA from '@/assets/images/sunnova.svg';
import TSUN from '@/assets/images/tsun.png';
import SUNNERGY from '@/assets/images/sunnergy.webp';
import LEAPTON from '@/assets/images/leapton.png';
import DEYE from '@/assets/images/deye.webp';
import PHONO from '@/assets/images/phono.png';
import GROWATT from '@/assets/images/growatt.svg';
import GOODWE from '@/assets/images/goodwe.svg';
import SAJ from '@/assets/images/saj.jpg';
import SUNGROW from '@/assets/images/sungrow.webp';
import FRONIUS from '@/assets/images/fronius.png';
import OSDA from '@/assets/images/osda.png';
import REFUSOL from '@/assets/images/refusol.png';
import Loading from '@/components/global/loading.vue';
import MessagesMixin from '@/mixins/messages-mixin';
import QuestionMixin from '@/mixins/question-mixin';
import ErrorMixin from '@/mixins/error-mixin';

export default {
  components: {
    Autocomplete,
    Loading,
  },
  props: ['client_id'],
  data() {
    return {
      kits: [],
      loading: false,
      client: '',
      clients: [],
      image_byd: BYD,
      image_trina: TRINA,
      image_jinko: JINKO,
      image_phono: PHONO,
      image_aesolar: AESOLAR,
      image_leapton: LEAPTON,
      image_dah: DAH,
      image_sunnova: SUNNOVA,
      image_tsun: TSUN,
      image_sunnergy: SUNNERGY,
      image_growatt: GROWATT,
      image_goodwe: GOODWE,
      image_fronius: FRONIUS,
      image_refusol: REFUSOL,
      image_sungrow: SUNGROW,
      image_saj: SAJ,
      image_deye: DEYE,
      image_osda: OSDA,
      provinces: [
        {
          text: 'Selecione',
          value: '',
        },
      ],
      selected: '',
      cities: [],
      telhado_colonial: TelhadoColonial,
      telhado_paraf_madeira: TelhadoParafMadeira,
      telhado_paraf_metal: TelhadoParafMetal,
      telhado_trapezoidal: TelhadoTrapezoidal,
      usina_solo: UsinaSolo,
      user: JSON.parse(localStorage.getItem('user')),
      show_kits: false,
      telhado_laje: TelhadoLaje,
      orcamento: false,
      province_id: '',
      types_construction: [
        {
          text: 'Selecione',
          value: '',
        },
        {
          text: 'Casa',
          value: 'casa',
        },
        {
          text: 'Galpão',
          value: 'galpao',
        },
        {
          text: 'Solo',
          value: 'solo',
        },
        {
          text: 'Sobrado / Barracão',
          value: 'sobrado_barracao',
        },
        {
          text: 'Outros',
          value: 'outros',
        },
      ],
      options_voltage: [
        {
          text: 'Selecione',
          value: '',
        },
        {
          text: 'Monofásico 220v / Bifásico 220v',
          value: 220,
        },
        {
          text: 'Trifásico 220v',
          value: 220,
        },
        {
          text: 'Trifásico 380v',
          value: 380,
        },
      ],
      payload: {
        power: '',
        city_id: '',
        agent_id: '',
        user_id: '',
        client_id: '',
        roof_type_id: '',
        type_construction: '',
        code: '',
        price: 0,
        panel_count: 0,
        price_cost: 0,
        voltage_stand: '',
        estimate_power: 0,
        kwp: 0,
        transformers: [],
        agent_percentage: 8,
        metreage: '',
        inverter: '',
        panel: '',
        inverter_id: '',
        panel_id: '',
      },
    };
  },

  validations: {
    payload: {
      power: { required, minValue: minValue(300) },
      voltage_stand: { required },
      agent_percentage: { required, maxValue: maxValue(8) },
    },
  },
  mixins: [MessagesMixin, QuestionMixin, ErrorMixin],
  methods: {
    async getProvinces() {
      const { data } = await HelperService.getProvinces();
      data.forEach((item) => {
        this.provinces.push({
          text: item.name,
          value: item.id,
        });
      });
    },

    async getCities() {
      const params = {
        province_id: this.province_id,
      };

      this.payload.province_id = this.province_id;

      const { data } = await HelperService.getCities(params);
      this.cities = data;
    },

    validate() {
      if (!this.payload.client_id && !this.orcamento) {
        this.messageError('Selecione o cliente');
        return false;
      }

      if (!this.payload.city_id) {
        this.messageError('Selecione a cidade');
        return false;
      }

      if (!this.payload.roof_type_id) {
        this.messageError('Selecione o tipo de telhado');
        return false;
      }

      return true;
    },

    async getClients() {
      let response = '';
      if (this.payload.agent_id > 0) {
        response = await ClientService.getAgentClients(this.payload.agent_id);
      } else {
        response = await ClientService.getList();
      }
      const { data } = response;

      if (data) {
        this.clients = data.map((item) => ({
          id: item.id,
          name: item.name,
        }));
      }
    },

    setCity(city) {
      this.payload.city_id = city.selectedObject.id;
    },

    setRoof(value) {
      if (this.kits.length > 0) {
        this.kits = [];
        this.messageInfo('Busque os Kits novamente');
      }
      this.payload.roof_type_id = value;
    },

    async getClient() {
      const { data } = await ClientService.get(this.client_id);
      this.client = data;

      this.payload.province_id = this.client.addresses[0].city.province.id;
      this.payload.city_id = this.client.addresses[0].city.id;
      this.city_name = this.client.addresses[0].city.name;
      this.getCities();
    },

    async getKits() {
      this.kits = [];

      if (!this.validate()) return;

      if (!this.$v.payload.$invalid) {
        try {
          this.loading = true;
          const { data } = await KitService.getKits(this.payload);

          data.forEach((item) => {
            this.kits.push({
              inverter_id: item.inverter_id,
              panel_id: item.panel_id,
              total_estimate_power: item.estimate_power,
              price: item.price,
              panel_name: item.panel_brand,
              panel: item.panel,
              inverter_name: item.inverter_brand,
              generators_total_panels: item.panel_count,
              discount_value: 0,
              transformer: item.transformer,
              estimate_power_kwp: item.estimate_power_kwp,
              price_cost: item.price_cost,
              inverter: item.inverter,
            });
          });

          this.show_kits = true;
        } catch (error) {
          console.log(error);
        }
      }
      this.loading = false;
    },

    setKit(kit) {
      console.log(kit);
      this.payload.kwp = kit.estimate_power_kwp;
      this.payload.panel_count = kit.generators_total_panels;
      this.payload.estimate_power = kit.total_estimate_power;
      this.payload.price_cost = kit.price_cost;
      this.payload.price = kit.price;
      this.payload.transformers = kit.transformer;
      this.payload.inverter = kit.inverter;
      this.payload.panel = kit.panel;
      this.payload.inverter_id = kit.inverter_id;
      this.payload.panel_id = kit.panel_id;
      this.payload.price_with_discount = kit.price_with_discount;
      this.payload.user_id = this.user.id;
      this.payload.discount_value = kit.discount_value;
      this.payload.percentage_discount = kit.percentage_discount;

      if (!this.orcamento && this.validate()) {
        this.confirmeCreate();
      }
    },

    setClient(client) {
      this.payload.client_id = client.selectedObject.id;
    },

    confirmeCreate() {
      this.$toast.question(
        `Deseja realmente criar o projeto com o Kit selecionado ? 
        O valor de comissão sobre a venda só poderá ser alterado pelo administrado da SUBSOLAR após a criação do projeto.`,
        'Atenção',
        this.getQuestionObj(this.save, '', 'Projeto criado'),
      );
    },

    applyDiscount() {
      const discountPercentage = 8 - parseFloat(this.payload.agent_percentage);
      this.kits.forEach((item, index) => {
        const valueDiscount = parseFloat(item.price) * parseFloat(discountPercentage / 100);
        this.kits[index] = {
          ...item,
          discount_value: valueDiscount,
          percentage_discount: discountPercentage,
          price_with_discount: item.price - valueDiscount,
          comission: (item.price - valueDiscount) * (parseFloat(this.payload.agent_percentage) / 100),
        };
        this.$forceUpdate();
      });
    },

    async save() {
      console.log(this.payload);
      const { data } = await ProjectService.save(this.payload);
      this.$router.push({ name: 'project', params: { id: data.id } });
    },
  },

  created() {
    this.getProvinces();
    const user = JSON.parse(localStorage.getItem('user'));

    if (localStorage.getItem('permission') === 'public') {
      this.payload.agent_id = user.agent.id;
    } else {
      this.payload.user_id = user.id;
    }

    this.getClients();
    if (this.client_id) {
      this.getClient();
    }
  },
};
</script>
<style lang="css" scoped>

.logos-container {
  height: 82px;
}
.roof-item{
  height: 170px;
  width: 100%;
  background-size: cover !important;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
}
.roof-item:hover{
  border: 2px solid #ff904d;
  cursor: pointer;
  transition: transform ease .5s;
}

.roof-hover{
  opacity: 0;
  background: #fe8824a8;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.roof-hover:hover{
  opacity: 1;
  transition: opacity ease .5s;
}

.roof-hover i{
  font-size: 40px;
  color: #fff;
}

.kit {
  position: relative;
}

.kit .percentage_discount{
  position: absolute;
  top: 5px;
  right: 5px;
  background: #fd5656;
  color: #fff;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
}

.selected .roof-hover {
  opacity: 1 !important;
}

.card-info-kit{
  justify-content: center;
  text-align: center;
  margin: 15px 0;
}

.w-70{
  width: 70%;
}

.w-60{
  width: 60%;
}

.text-line-through{
  text-decoration: line-through;
}

.card-info-kit div{
  width: 48%;
  margin: 0 2%;
  background: #ff904d;
  padding: 10px 0;
}
</style>
